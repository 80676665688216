
import {timeFormat} from "@custom";

export const tableColumn = [
    {
        type: "index",
        label: "编号",
        width: 100,
    },
    {
        prop: "name",
        label: "姓名",
        // width: 160,
    },
    {
        prop: "type",
        label: "员工类型",
        // width: 200,
    },
    {
        prop: "phone",
        label: "手机",
        // width: 200,
    },
    {
        prop: "company",
        label: "所属公司",
        // width: 200,
    },
    
];