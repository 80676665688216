import request from '@http';
// 2023/05/17 王珂 新增第三方人员
export function addUser(data) {
    return request({
        method: 'POST',
        url: `/api/gzf/dc/unlock/request/user/save`,
        data
    });
}
// 2023/05/17 王珂 第三方人员删除
export function delFamily(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/unlock/request/user/delete`,
        params
    });
}
// 2023/05/17 王珂 第三方人员修改
export function modifier(data) {
    return request({
        method: 'POST',
        url: `/api/gzf/dc/unlock/request/user/update`,
        data:data
    });
}
// 2023/05/17 王珂 第三方人员列表
export function personList(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/unlock/request/user/list`,
        params
    });
}
// 2023/05/17 王珂 第三方人员修改信息回显
export function getProceedsPayTotalApi(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/unlock/request/user/uuid`,
        params
    });
}



